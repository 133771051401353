/**
 * Gallery slider
 *
 * @since 1.0.0
 * @package paradry
 */

(function($) {
	$(document).ready(function() {



	}); // document.ready
})(jQuery);
